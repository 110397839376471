<template>

  <b-card>

    <b-form
        class="p-2"
    >
      <!-- Nombres -->
      <b-form-group label="Nombres" label-for="usr-nombres">
        <b-form-input
            id="usr-nombres"
            v-model="usuario.usrNombres"
            autofocus
            trim
            maxlength="30"
        />
      </b-form-group>
      <!-- Apellidos -->
      <b-form-group label="Apellidos" label-for="usr-apellidos">
        <b-form-input
            id="usr-apellidos"
            v-model="usuario.usrApellidos"
            autofocus
            trim
            maxlength="30"
        />
      </b-form-group>
      <!-- Correo -->
      <b-form-group label="Correo electrónico" label-for="email">
        <b-form-input
            id="email"
            v-model="usuario.email"
            autofocus
            trim
            maxlength="30"
            type="email"
        />
      </b-form-group>



      <div class="d-flex align-items-center mt-2">
        <label>Tipo de usuario</label>
        <b-form-radio-group v-model="usuario.usrType" inline class="ml-2">

          <b-form-radio
              value="1"
              class="custom-control-warning"
          >
            Talento humano
          </b-form-radio>
          <b-form-radio
              value="2"
              class="custom-control-info"
          >
            Jefatura
          </b-form-radio>
          <b-form-radio
              value="3"
              class="custom-control-danger"
          >
            Seguridad
          </b-form-radio>


        </b-form-radio-group>
      </div>

      <div class="d-flex align-items-center mt-2">
        <label>Usuario activo</label>
        <b-form-checkbox v-model="usuario.isActive" switch class="ml-2"/>
      </div>


      <hr>
      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            :disabled="isBusy || hasSomeNullEmptyItem"
            @click.prevent="handleSubmit"
        >
          <template v-if="isBusy">
            <b-spinner small/>
            Guardando
          </template>
          <template v-else>
            Guardar
          </template>
        </b-button>
        <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :disabled="isBusy"
            @click="handleDiscard"
        >
          Descartar
        </b-button>
      </div>

    </b-form>

    <validation-errors v-if="errors" :errors="errors"/>

  </b-card>

</template>

<script>

import {
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormRadio,
  BButton,
  BSpinner,
  BFormCheckbox
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

import ValidationErrors from '@/modules/components/ValidationErrors.vue'
import { mapActions } from 'vuex'

export default {
  name: 'UsuarioEdit',
  props: {
    usuarioProp: {
      type: Object,
      required: true,
      default: {}
    }
  },
  components: {
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormRadio,
    BButton,
    BSpinner,
    BFormCheckbox,
    ValidationErrors,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {

    return {
      errors: [],
      isBusy: false,
      showSidebar: false,

      usuario: {
        usrNombres: this.usuarioProp.usrNombres,
        usrApellidos: this.usuarioProp.usrApellidos,
        email: this.usuarioProp.email,
        usrType: this.usuarioProp.usrType,
        isActive: this.usuarioProp.usrEstado == 1 ? true : false
      },


      // 0 admin 1 talento humano 2 jefatura 9 root
      optionsType: [
        { text: 'Talento humano', value: 1 },
        { text: 'Jefatura', value: 2 },
      ],
    }

  },
  computed: {

    // verificar si algun elemento de un objeto es NULL o vacío
    hasSomeNullEmptyItem () {
      const nullable = ( element ) => element === null || element === ''
      return Object.values( this.usuario ).some( nullable )
    },

  },

  methods: {

    ...mapActions('usuario-module', ['updateUsuario', 'getUsuarios']),


    // procesar peticion de guardar
    async handleSubmit(){


      try {

        this.errors = []

        this.isBusy = true

        const params = {
          id: this.usuarioProp.id,
          usrUuid: this.usuarioProp.usrUuid,
          usrNombres: this.usuario.usrNombres,
          usrApellidos: this.usuario.usrApellidos,
          email: this.usuario.email,
          usrType: this.usuario.usrType,
          usrEstado: this.usuario.isActive ? 1 : 0
        }

        await this.updateUsuario( params )

        this.$emit('successEditMode')

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sistema',
            icon: 'CheckIcon',
            text: '¡Usuario actualizado exitosamente!',
            variant: 'success'
          },
        })

        this.showSidebar = false

      } catch ( error ) {

        if ( error.response.status == 422 ) {

          const  data  = error.response.data.errors

          console.log( "UNXPECTED", error.response.data )

          // errores de validacion
          this.errors = data

        }


      } finally {
        this.isBusy = false
      }

    },

    // procesar peticion de descartar
    handleDiscard(){
      this.$emit('closeEditMode')
    },

    // reset los campos del formulario
    resetForm(){
      Object.keys(this.usuario).forEach( key =>  this.usuario[ key ] = null )
    }

    //
  },

  created() {
    console.log("created edit", this.usuario )
  }

}
</script>

<style scoped>

</style>
