<template>

  <div>

    <b-alert
        v-height-fade.appear
        variant="danger"
        class="mb-0"
        show
        v-for="(error, index) in errorsList"
        :key="index"
    >
      <div class="alert-body">
        <feather-icon
            icon="InfoIcon"
            class="mr-50"
        />
        {{ error }}
      </div>
    </b-alert>

  </div>


</template>

<script>

import { BAlert } from 'bootstrap-vue'
import { heightFade } from '@core/directives/animations'

export default {
  name: 'ValidationErrors',
  props: [ 'errors'],
  components: {
    BAlert
  },
  directives: {
    'height-fade': heightFade,
  },
  computed: {
    errorsList(){
      return Object.values(this.errors).flat()
    }
  }

}

</script>

<style scoped>

</style>
